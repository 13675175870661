.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #D81E23;
}
.btnContent {
  align-items: end;
  display: flex;
  justify-content: end;
}
.downloadbtn {
  position: relative;
  left: 80%;
  justify-content: center;
  margin: 10px 0px 10px 10px;
  background: #DEDDDB;
  color: #282c34;
  text-decoration: none;
  padding: 10px 10px;
  border-radius: 5px 5px 5px 5px;
}

.select {
  display: grid;
}

/* Authentification page */
.amplify-button[data-variation='primary'] {
  background: #D81E23;
}

.amplify-button[data-variation='link'] {
  color: #D81E23;
  margin-bottom: 10px;
}

.amplify-button[data-variation='primary']:hover {
  color: #D81E23;
  background: #ffff;
  border-color: #D81E23;
}

/* CSS for the loading screen overlay */
.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Set a high z-index to appear on top of other content */
}

.loader {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid #fff;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

/* Keyframes for the spinner animation */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
